import React from 'react';
import { graphql, navigate } from 'gatsby';
import LoginButton from 'components/Login';

import '@react-website-themes/classy-docs/styles/variables';
import '@react-website-themes/classy-docs/styles/global';

import Helmet from 'react-helmet';
import Branding from '@react-website-themes/classy-docs/components/Branding';
import Footer from '@react-website-themes/classy-docs/components/Footer';
import Header from '@react-website-themes/classy-docs/components/Header';
import Hero from '@react-website-themes/classy-docs/components/Hero';
import Layout from '@react-website-themes/classy-docs/components/Layout';
import Menu from '@react-website-themes/classy-docs/components/Menu';
import Article from '@react-website-themes/classy-docs/components/Article';
import LogoutButton from 'components/Logout';
import styled from 'react-emotion';
import { css } from 'emotion';

import config from 'meta/config';
import menuItems from 'meta/menu';
import Logo from 'assets/images/logo.png';
import { isAuthenticated } from '../Auth/AuthService';


const HeroButton = styled.button`
  align-items: center;
  border-radius: 3px;
  color: var(--firstActiveColor);
  font-size: 1em;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1em 2em;
  background: #f9f9f9;
  margin: 40px 0 0;
`;

const HideCredits = css`
  .credits {
    display: none;
  }
`;

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  componentDidMount() {
    this.setAuthentication();
  }

  setAuthentication() {
    this.setState({ authenticated: isAuthenticated() });
  }

  render() {
    const {
      location: {
        pathname,
      },
      data: {
        hero: {
          edges: heroEdges,
        },
        footerLinks: {
          edges: footerLinksEdges,
        },
        copyright: {
          edges: copyrightEdges,
        },
      },
    } = this.props;

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: footerLinksHTML,
          },
        },
      },
    } = footerLinksEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: copyrightHTML,
          },
        },
      },
    } = copyrightEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: heroHTML,
          },
        },
      },
    } = heroEdges[0];

    const {
      headerTitle,
      headerSubTitle,
    } = config;

    const { authenticated } = this.state;

    return (
      <Layout>
        <Helmet title="Home | Able Policies" />
        <Header>
          <Branding title={headerTitle} subTitle={headerSubTitle} />
          <Menu items={menuItems} />
          {' '}
          {authenticated && (
            <LogoutButton
              setAuthentication={this.setAuthentication}
              pathname={pathname}
            />)
          }
        </Header>
        <Hero>
          <img src={Logo} className="image" alt="site logo" />
          <div dangerouslySetInnerHTML={{
            __html: heroHTML,
          }}
          />
          {' '}
          {
            authenticated && (
              <Article>
                <HeroButton onClick={() => navigate('/search')}>
                  Search
                </HeroButton>
              </Article>
            )
          }
          {!authenticated && (
            <LoginButton setAuthentication={this.setAuthentication} pathname={pathname} />
          )}
        </Hero>
        <Footer customStyle={HideCredits} links={footerLinksHTML} copyright={copyrightHTML} />
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query {
    hero: allContentfulParts(
      filter: {
            title: { eq: "hero"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    footerLinks: allContentfulParts(
      filter: {
            title: { eq: "footerLinks"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    copyright: allContentfulParts(
      filter: {
            title: { eq: "copyrightNote"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
